import { Avatar, AvatarImage, AvatarFallback } from '@components/ui/avatar';
import { useSession } from '@hooks/index'; // Import the useSession hook
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover';
import { Button } from '@components/ui/button';
import React from 'react';
import { LogOut, Settings } from 'lucide-react';
import router from 'next/router';

export default function AvatarMenu() {
  const { user, logOut, isLoggedIn } = useSession(); // Get the user object from useSession hook
  const userName = user?.name || 'Guest'; // Get the user's name or use 'Guest' as the default

  return (
    <>
      {isLoggedIn && (
        <Popover>
          <PopoverTrigger>
            <Avatar className="w-8 h-8">
              <AvatarImage src={user?.image} alt={userName} />
              <AvatarFallback>{userName.charAt(0)}</AvatarFallback>
            </Avatar>
          </PopoverTrigger>
          <PopoverContent align="end" className="flex flex-col gap-y-2">
            <div className="flex flex-col items-center justify-start p-4">
              <Avatar className="w-12 h-12 mb-4">
                <AvatarImage src={user?.image} alt={userName} />
                <AvatarFallback>{userName.charAt(0)}</AvatarFallback>
              </Avatar>
              {userName}
            </div>
            <Button
              variant="secondary"
              size="sm"
              className="w-full !justify-start !text-left cursor-pointer"
              onClick={() => router.push('/settings')}
            >
              <Settings size={16} className="mr-2" /> Account Settings
            </Button>

            <Button
              variant="secondary"
              size="sm"
              className="w-full !justify-start !text-left cursor-pointer"
              onClick={() => logOut()}
            >
              <LogOut size={16} className="mr-2" /> Log Out
            </Button>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
}
