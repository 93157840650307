export const getPaymentsCount = async () => {
  const url = `/api/payments/count`;

  const headers: HeadersInit = { 'Content-Type': 'application/json' };
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
    headers: headers
  });

  const data = await response.json();

  return data.count as number;
};
